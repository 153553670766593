var FormInputMapper = function () {

    "use strict";

    var self = this;

    this.initialize = function () {
        this.eventDelegation();
        this.activateItemFromInputValue();
    };

    this.eventDelegation = function () {
        $("body").on("change", "[data-input-type=mapper]", function () {
            self.setValueToInput(this);
        });

        $("body").on("click", "[data-input-type=mapper]", function (event) {
            if ($(event.target).is('a')) {
                return;
            }
            self.activateInput(this, '[data-input-group=' + $(this).data('input-group') + ']');
            self.setValueToInput(this);
        });
        $('[data-mapper=relatedinput]').change(function (event) {
            var inputMapped = $(this).parents('[data-input-type=mapper]');

            if (inputMapped.length == 0) {
                inputMapped = $(this).parents('[data-input-type=mapper-no-status]');
            }
            if ($(this).val() == 0) {
                self.activateInput(inputMapped);
            }
            self.setValueToInput(inputMapped);
        });

        $('[data-form-sibling] :input, [data-form-sibling] select').change(function (event) {
            self.copyValueToSiblingInput($($(this).parents('form').data('form-sibling')), $(this));
        });
    };

    this.activateItemFromInputValue = function () {
        $("[data-input-type=mapper]").each(function (index) {
            if ($('[name=' + $(this).data("input") + ']').val() > 0) {
                $(this).addClass('active-item');
            }
        });
    };

    this.activateInput = function (input, nonTarget) {
        var parent = $(input).parents();
        if (parent.data('input-group')) {
            if(parent.data('only-one-selectable')){
                if ($(input).hasClass('active-item')) {
                    $(input).removeClass('active-item');
                } else {
                    $(nonTarget).each(function () {
                        var inputName = parent.data('only-one-selectable') + "_" + $(this).data('id');
                        $('[name="' + inputName +'"]').val(0);
                    });
                    $(nonTarget).removeClass('active-item');
                    $(input).addClass('active-item');
                }
            } else {
                $(nonTarget).removeClass('active-item');
                $(input).addClass('active-item');
            }
        } else {
            if (!$(input).hasClass('disabled')) {
                $(input).toggleClass('active-item');
            } else {
                $(input).removeClass('active-item');
            }
        }
    };

    this.setValueToInput = function (elem) {
        if ($(elem).hasClass('active-item') || $(elem).data('input-type') == 'mapper-no-status') {
            if ($(elem).find('[data-mapper=relatedinput]').length > 0) {
                if ($(elem).find('[data-mapper=relatedinput]').val()){
                    $('[name=' + $(elem).data('input') + ']').val($(elem).find('[data-mapper=relatedinput]').val());
                } else {
                    let input = $('[name=' + $(elem).data('input') + ']');
                    let maxOptionValue = $($(elem).find('[data-mapper=relatedinput] option')[$(elem).find('[data-mapper=relatedinput] option').length -1]).val()

                    $(elem).find('[data-mapper=relatedinput]').val(maxOptionValue)
                    input.val(maxOptionValue);
                }
            } else {
                $('[name=' + $(elem).data('input') + ']').val($(elem).data('input-value'));
            }
        } else {
            $('[name=' + $(elem).data('input') + ']').val(0);
            $(elem).find('[data-mapper=relatedinput]').val($(elem).data('input-value'));
        }
    };

    this.copyValueToSiblingInput = function (siblingForm, input) {
        var value = input.val();
        var siblingInput = siblingForm.find('[data-input-sibling=' + input.data('input-sibling') + ']');

        var siblingInputContainer = siblingInput.parents('[data-input-container]');

        if (siblingInput.val() != value) {
            siblingInput.val(value);
            siblingInput.change();
        }
        
        siblingInput.show();
        
        if(typeof siblingInput.data('parent') != 'undefined' && value != 0) {
            $(siblingInput.data('parent')).show();
        } else if (typeof siblingInput.data('parent') != 'undefined' && value == 0) {
            $(siblingInput.data('parent')).hide();
        }

        if (siblingInputContainer.is(':hidden')) {
            siblingInputContainer.show();
        }
    };

    this.setArrayOfValuesToInput = function (input, inputMapper) {
        var array_values = [];
        $(inputMapper).each(function (index) {
            if (($(this).is(':checkbox') && $(this).is(':checked')) || (!$(this).is(':checkbox'))) {
                if ($(this).val() != '') {
                    array_values.push($(this).val());
                }
            }
        });
        $(input).val(array_values.join(';'));
    };
    this.initialize();
};

export default FormInputMapper;
