var DomAnimator = function () {
    "use strict";

    var self = this;
    var target, showClass;

    this.initialize = function () {
        this.collapseSide();
        this.initializeEventDelegation();
    };

    this.initializeEventDelegation = function () {
        $('#menu_overlay').on('click', function () {
            self.closeCollapseSide();
            $("html, body").removeClass("modal-open");
        });
        $('.js_close_menu').on('click', function () {
            self.closeCollapseSide();
            $("html, body").removeClass("modal-open");
        });
        $(window).on("resize", function () {
            if (myApp.deviceDetector.isLgScreen() || myApp.deviceDetector.isMdScreen()) {
                self.closeCollapseSide();
            }
        });
    };

    this.collapseSide = function () {
        $('[data-toggle=collapse-side]').click(function () {
            target = $(this).attr('data-target');
            showClass = $(this).attr('data-show');

            if (!$(target).hasClass(showClass)) {
                self.closeCollapseSide();
                $("html, body").removeClass("modal-open");
                $(target).addClass(showClass);
                $('#menu_overlay').fadeIn();
            } else {
                $(target).removeClass(showClass);
                $(target).removeClass(showClass);
                $('#menu_overlay').fadeOut();
            }
        });
    };

    this.closeCollapseSide = function () {
        $('.side-menu').removeClass('show');
        $('#menu_overlay').hide();
        $("html, body").removeClass("no-overflow");
    };

    this.fadeInHiddenElement = function (target, nonTarget) {
        $(nonTarget).hide();
        $(target).fadeIn();
    };

    this.showElementWithDelay = function (element, delay) {
        $(element).show();
        setTimeout(function () {
            $(element).hide();
        }, delay);
    };

    this.showAnimatedHeart = function (element) {
        let mainHeart = $(element).find('[data-entity="mainHeart"]');
        if (mainHeart.length > 0) {
            for (let i = 1; i < 5; i++) {
                let lastAnimatedHeart = (i === 4 ? 'data-entity="animatedHeart"' : '');
                let icon = '<i class="ci ci-heart animated absolute icon-'+i+'" data-entity="hearths" '+ lastAnimatedHeart +'></i>';
                $(element).children().append(icon);
            }
        }
    };

    this.animateHeart = function (element, response) {
        let animatedHearts = element.find('[data-entity="animatedHeart"]');
        let hearths = element.find('[data-entity="hearths"]');
        hearths.addClass('active');

        hearths.on("transitionend", function() {
            $(this).remove();
        })

        animatedHearts.on("transitionend", function () {
            if (response) {
                element.hide();
            }
        });
    }
    this.initialize();
};

export default DomAnimator;