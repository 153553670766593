var OverlayMenuToggler = function () {

    "use strict";

    var self = this;

    this.initialize = function () {
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $('[data-navtabs-overlay]').on('click', function () {
            self.closeMenu()
        });

        $('[data-navtabs]').on('show.bs.collapse', function () {
            self.showOverlay();
        });

        $('[data-navtabs]').on('hide.bs.collapse', function () {
            self.hideOverlay();
        });

        $('[data-navtabs] li').on('click', function () {
            myApp.urlManager.setUrlParam('rid', $(this).data('tab'));
            self.closeMenu();
        });
    };

    this.closeMenu = function () {
        $('[data-navtabs]').collapse('hide');
        this.hideOverlay();
    };

    this.hideOverlay = function () {
        $('[data-navtabs-overlay]').fadeOut();
    };

    this.showOverlay = function () {
        $('[data-navtabs-overlay]').fadeIn();
    };
    this.initialize();
};

export default OverlayMenuToggler;