var EmailCleaner = function () {

    "use strict";

    var self = this;

    var emailDomains = {
        'jmail.com': 'gmail.com',
        'gmeil.com' : 'gmail.com',
        'gmai.com': 'gmail.com',
        'gmsil.com': 'gmail.com',
        'gtmail.com': 'gmail.com',
        'gemail.com': 'gmail.com',
        'g-mail.com': 'gmail.com',
        'gmaiol.com': 'gmail.com',
        'gmmail.com': 'gmail.com',
        'gemal.com': 'gmail.com',
        'gmail.vom' : 'gmail.com',
        'gmail.con' : 'gmail.com',
        'gmail.xom' : 'gmail.com',
        'hotmial.com': 'hotmail.com',
        'homatil.com': 'hotmail.com',
        'homail.com': 'hotmail.com',
        'hostmail.com': 'hotmail.com',
        'homtail.com': 'hotmail.com',
        'hodtmail.com': 'hotmail.com',
        'hotamail.com': 'hotmail.com',
        'hoymail.com': 'hotmail.com',
        'h.otmail.com': 'hotmail.com',
        'hogmail.com': 'hotmail.com',
        'hotnail.com': 'hotmail.com',
        'hotmail.con': 'hotmail.com',
        'hotmail.von': 'hotmail.com',
        'hotmail.xom': 'hotmail.com',
        'hotamil.com': 'hotmail.com',
        'yohoo.es': 'yahoo.es',
        'yaoo.com': 'yahoo.com',
        'yhooo.com': 'yahoo.com',
        'yaooh.es': 'yahoo.es',
        'yahhoo.com': 'yahoo.com',
        'yaho.com': 'yahoo.com',
        'hayoo.es': 'yahoo.es',
        'yahoo.e': 'yahoo.es',
        'yahoo.con': 'yahoo.com',
        'yahoo.xom': 'yahoo.com',
        'yayoo.vom': 'yahoo.com'};


    this.clear = function (email) {
        if (email.includes('@')) {
            email = email.split(' ').join('');
            email = email.toLowerCase();
            while (!email.charAt(email.length - 1).match(/[a-z]/)) {
                email = email.substr(0, email.length - 1);
            }
            var split = email.split('@');
            var name = split[0];
            var domain = split[1];
            domain = domain.split(',').join('.');
            if (domain in emailDomains) {
                domain = emailDomains[domain];
            }
            email = name + '@' + domain;
        }
        return email;
    };
};

export default EmailCleaner