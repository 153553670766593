import SearcherManager from "../search/searcherManager";

export default class ExclusiveDialogModal {

    constructor() {
        this.hasExclusiveModal = document.querySelector('#exclusiveDialogModal');
        this.openElementsMobile = document.querySelectorAll('[data-action="open-exclusive-banner-mobile"]');
        this.eventDelegation();
    }

    handleIntersection = (elements ,observer)=> {
        elements.forEach(element => {
            if (element.isIntersecting) {
                const elementShown = element.target;
                elementShown.dispatchEvent(new Event('showExclusiveDialogBanner'));
                observer.unobserve(elementShown);
            }
        });
    }
    eventDelegation() {
        const bookingDatesElement = document.querySelector(SearcherManager.inputs.bookingDates);

        if(!bookingDatesElement) return;

        bookingDatesElement.addEventListener('openCalendar', () => {
            const hasOpenModal = document.querySelector('#exclusiveDialogModal')
            if (hasOpenModal){
                myApp.customModalManager.closeModal('#exclusiveDialogModal');
            }
        });

        const hasCookie = myApp.browserStorageService.findCookie('hide_exclusive_banner');

        if (this.openElementsMobile && this.hasExclusiveModal && !hasCookie && myApp.deviceDetector.isXsScreen()) {

            const observer = new IntersectionObserver(this.handleIntersection);

            this.openElementsMobile.forEach(element => {
                observer.observe(element, observer);
            })

            this.openElementsMobile.forEach(openElement => {
                openElement.addEventListener('showExclusiveDialogBanner', (event) => {
                    myApp.customModalManager.openModal('#exclusiveDialogModal');
                    myApp.browserStorageService.setCookie('hide_exclusive_banner', '7-d');
                });
            });
        }
    };
}