import CustomMapBuilder from "./customMapBuilder";
import CustomMap from "../../entity/customMap/customMap";

export default class HotelCustomMapBuilder
{
    constructor()
    {
        this.defaultMapMaParameters = {
            latitude: 40.416775,
            longitude: -3.703790,
            zoom: 15
        };
        this.hotelMapId = 'hotel-custom-map';
        this.customMapBuilder = new CustomMapBuilder();
        this.eventDelegation();

        const hotelMapContainer = $('[data-action="load-hotel-map"]');
        if (hotelMapContainer.length) {
            this.initializeHotelContentMap(hotelMapContainer);
        }
    }

    eventDelegation()
    {
        $('body').on('shown.bs.collapse', '[data-entity="collapse-hotel"]', (event) =>
        {
            const eventCurrentTarget = $(event.currentTarget);
            this.initializeHotelContentMap(eventCurrentTarget);
        });
    }

    initializeHotelContentMap(currentTarget) {
        let mapContainer = currentTarget.find('[data-entity="custom-map"][data-type="hotel"]');
        const mapId = mapContainer.attr('id');
        let map = this.customMapBuilder.getMap(mapId);
        if (!(map instanceof CustomMap) || mapContainer.html().trim() === "") {
            if (map instanceof CustomMap) {
                this.customMapBuilder.removeMap(mapId);
            }
            map = this.buildFromDom(mapId);
        }
        let markerId = currentTarget.find('[data-entity="hotel"][data-custom-map-print="' + mapId + '"]').data('id');
        markerId = this.getMarkerId(markerId);
        map.openPopupByMarker(markerId);
        setTimeout(() => { map.invalidateSize()}, 500);
    }

    buildFromDom(mapId)
    {
        let hotelData = $('#' + mapId);
        let latitude = hotelData.data('latitude') ?? this.defaultMapMaParameters.latitude;
        let longitude = hotelData.data('longitude') ?? this.defaultMapMaParameters.longitude;
        const map = this.customMapBuilder.addMap(
            mapId,
            latitude,
            longitude,
            this.defaultMapMaParameters.zoom
        );
        $.each($('[data-entity="hotel"][data-custom-map-print="' + mapId + '"]'), (index, element) =>
        {
            const markerElement = $(element);
            const markerId = this.getMarkerId(markerElement.data('id'));
            const popupContent = $('[data-entity="custom-map-popup"][data-id="' + markerId + '"]').html();

            this.customMapBuilder.addMarker(
                map.id,
                markerId,
                markerElement.data('latitude'),
                markerElement.data('longitude'),
                popupContent
            );
        });
        map.addLayer();
        map.fitBounds(map.arrayMarkers);

        return map;
    }

    getMarkerId(hotelId)
    {
        return 'h-' + hotelId;
    }
}
