import Calendar from "../../entity/calendar";

var BookingDatepickerConfigurator = function () {

    "use strict";

    var self = this;
    this.maxDays = 14;

    this.eventDelegation = function () {

    };

    this.openEndDateDatepicker = function (startElem, endElem, startCalendar, endCalendar) {
        var startDatepicker = startCalendar.getDatepicker();

        startDatepicker.datepicker('option', 'onSelect', function () {
            $(this).change();
            setTimeout(function () {
                self.initializeEndCalendarFromStartCalendar(startCalendar, endCalendar);
                endCalendar.show();
                myApp.datepickerPrinter.hightlightDateRang(endCalendar.getDatepicker(), startCalendar.getDate());
                myApp.datepickerValidator.revalidateDatepickerField(startElem);
                myApp.datepickerValidator.revalidateDatepickerField(endElem);
            }, 200);
        });
    };

    this.initializeBookingDatepickers = function (startCalendarId, endCalendarId) {
        var startCalendar = new Calendar(startCalendarId);
        var endCalendar = new Calendar(endCalendarId);
        this.openEndDateDatepicker(startCalendarId, endCalendarId, startCalendar, endCalendar);
    };

    this.initializeEndCalendarFromStartCalendar = function (startCalendar, endCalendar) {

        var maxSearchEndDate = startCalendar.getDate();
        var minSearchEndDate = startCalendar.getDate();
        var defaultSearchEndDate = startCalendar.getDate();
        var startCalendarMaxDate = startCalendar.getMaxDate();

        maxSearchEndDate.setDate(maxSearchEndDate.getDate() + this.maxDays);

        // El rango de fechas es menor a this.maxDays
        if (startCalendar.getMaxDate() && startCalendar.getMaxDate() < maxSearchEndDate) {
            maxSearchEndDate.setTime(startCalendarMaxDate.getTime());
            maxSearchEndDate.setDate(startCalendarMaxDate.getDate() + 1);
            defaultSearchEndDate.setDate(defaultSearchEndDate.getDate() + 1);
            endCalendar.setMaxDate(maxSearchEndDate);
        // El rango de fechas es igual o mayor a this.maxDays o startCalendar.getMaxDate() no esta seteado.
        } else {
            defaultSearchEndDate.setDate(defaultSearchEndDate.getDate() + 2);
            endCalendar.setMaxDate(maxSearchEndDate);
        }

        minSearchEndDate.setDate(minSearchEndDate.getDate() + 1);
        endCalendar.setMinDate(minSearchEndDate);
        endCalendar.setDate(defaultSearchEndDate);
        
        $('#' + endCalendar.datepickerId).change();
    };
};

export default BookingDatepickerConfigurator;