export default class ContactFormValidator
{
    constructor()
    {
        this.eventDelegation();
    }

    eventDelegation()
    {
        $("#default-contact")
            .formValidation({
                live: 'disabled',
                icon: myApp.formValidator.getFeedbackIcons(),
                fields: {
                    nombre: {
                        validators: {
                            notEmpty: {
                                message: myApp.translator['contact.contactus.body.hasnotbooking.form.errors.name']
                            }
                        }
                    },
                    apellidos: {
                        validators: {
                            notEmpty: {
                                message: myApp.translator['contact.contactus.body.hasnotbooking.form.errors.surname']
                            }
                        }
                    },
                    email2: {
                        validators: {
                            notEmpty: {
                                message: myApp.translator['contact.contactus.body.hasnotbooking.form.errors.email']
                            },
                            regexp: {
                                regexp: myApp.formValidator.getEmailValidatorRegExp(),
                                message: myApp.translator['contact.contactus.body.hasnotbooking.form.errors.invalidemail']
                            }
                        }
                    },
                    phone: {
                        validators: {
                            callback: {
                                callback: function(value, validator, $field) {
                                    return window.intlTelInputGlobals.getInstance($field.get(0)).isValidNumber();
                                },
                                message: myApp.translator['contact.contactus.body.hasnotbooking.form.errors.phone']
                            }
                        }
                    },
                    confirmo: {
                        validators: {
                            notEmpty: {
                                message: myApp.translator['contact.contactus.body.hasnotbooking.form.errors.conditions']
                            }
                        }
                    }
                }
            })
            .on('keyup keypress', function (e)
            {
                var keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();
                }
            })
            .on('success.field.fv', function (e, data)
            {
                myApp.formValidator.disableSuccessAlerts(e, data);
            })
            .on('err.field.fv', function (e, data)
            {
                $('#default-contact-options').val(0);
                myApp.formValidator.disableContinuousErrorValidation(e, data);
            })
            .on('success.form.fv', function (e)
            {
                e.preventDefault();
            });
    }
}


