var CharacterInputHandler = function () {
    "use strict";

    var self = this;

    this.initialize = function () {
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $("[data-handler=character]").keyup(function () {
            $("[data-incorrect-iban-information]").hide();
            self.inputHandler(this);
        });
        
        $("body").on("paste", "[data-max-num]", function () {
            var $inputs = $(".def-txt-input");
            var $this = $(this);
            var originalValue = $this.val();

            $this.val("");

            $this.one("input.fromPaste", function () {
                var currentInputBox = $(this);

                var pastedValue = currentInputBox.val();

                if (pastedValue.length) {
                    pasteValues(pastedValue);
                }
                else {
                    $this.val(originalValue);
                }
            });

            $inputs.attr("maxlength", 35);
        });
    };

    this.inputHandler = function (elem) {
        if (elem.value.length >= $(elem).attr('maxlength')) {
            if ($('[data-handler-num=' + (parseInt($(elem).data('handler-num')) + 1) + ']').val() != '') {
                $('[data-handler-num=' + (parseInt($(elem).data('handler-num')) + 1) + ']').val('');
            }
            $('[data-handler-num=' + (parseInt($(elem).data('handler-num')) + 1) + ']').focus();
        }
    };
    this.initialize();
};
export default CharacterInputHandler;

// Parses the individual digits into the individual boxes.
function pasteValues(element) {
    var values = element.split("");
    var inputMaxValue = '';
    var inputBox;
    var maxNum;
    var count = 0;

    $(values).each(function (index) {
        if (maxNum <= (index + 1) && typeof (maxNum) != 'undefined') {
            inputMaxValue += values[index];
            if (count == 0) {
                inputBox = $('.def-txt-input[data-handler-num="' + (index + 1) + '"]');
            }
            count++;
        } else {            
            inputBox = $('.def-txt-input[data-handler-num="' + (index + 1) + '"]');
            if (inputBox.data('max-num')) {
                maxNum = inputBox.data('max-num');
            }
            inputBox.val(values[index]);
        }
    });
    if (inputMaxValue != '') {
        inputBox.val(inputMaxValue);
    }
};
