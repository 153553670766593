import AjaxManager from "./ajaxManager";

var ReviewsManager = function () {
    "use strict";

    var self = this;
    this.clonedReview;
    this.clonedReviewCarousel = '';
    this.arrayReviews = {};
    this.arrayNoReviews = {};
    this.existMoreReviews;
    this.newReviews = '';
    this.ajaxManager = new AjaxManager();

    this.initialize = function () {
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $("body").on("click",".show-more-comments", function () {
            self.newReviews = '';
            self.existMoreReviews = 1;
            var button = $(this);
            var id = button.attr('data-id');
            var limit = parseInt(button.attr('data-limit'));
            var start = parseInt(button.attr('data-start'));
            var type = button.attr('data-type');
            var action = button.attr('data-action');
            var elem = $('.' + button.attr('data-elem'));
            var elemCarousel = $('.' + button.attr('data-elemCarousel') + ':first');
            var excludedReview = button.attr('data-hotel-survey');
            button.attr('data-start', (start + limit));
            button.addClass('disabled');
            button.find('i').removeClass('hidden');

            $.when(self.showMoreReviews(id, start, limit, action, elem, elemCarousel, type, excludedReview)).done(function () {
                if ((self.arrayReviews.length == 0 && self.arrayNoReviews.length == 0) || self.existMoreReviews == 0) {
                    button.remove();
                }
                button.removeClass('disabled');
                button.find('i').addClass('hidden');
            });
        });
        
        $("body").on("click", "[data-action='load-reviews']", function () {
            self.showMoreReviewsByReceiver(this);
        });
    };

    this.showMoreReviews = function (id, start, limit, action, elem, elemCarousel, type, excluded) {
        var deferred = $.Deferred();
        $.when(self.getMoreReviews(id, start, limit, action, excluded)).done(function () {
            if (self.arrayReviews.length > 0 || self.arrayNoReviews.length > 0) {
                self.cloneReview(elem);
                self.cloneReviewCarousel(elemCarousel);
                if (self.arrayReviews.length > 0) {
                    $.each(self.arrayReviews, function () {
                        self.fillClonedReview(this, self.clonedReview, true);
                    });
                }

                self.clonedReviewCarousel.find("article").each(function (index) {
                    self.fillClonedReview(self.arrayNoReviews[index], $(this), false);
                });

                self.printReviews(id, type);
            }
            deferred.resolve();
        });
        return deferred.promise();
    };

    this.getMoreReviews = function (id, start, limit, action, excluded) {
        var excludedParam = '';
        if (excluded) {
            excludedParam = "/" + excluded;
        }
        var deferred = $.Deferred();
        $.getJSON(action + id + '/' + start + '/' + limit + excludedParam, function (data) {
        }).done(function (data) {
            self.arrayReviews = data.reviews;
            self.arrayNoReviews = data.no_reviews;
            self.existMoreReviews = data.more_reviews;
            deferred.resolve(data);
        });

        return deferred.promise();
    };

    this.fillClonedReview = function (review, clonedElem, print) {
        var newReview = '';
        if (review != undefined) {
            var reviewDate = new Date(review.data_inicio);
            newReview = clonedElem;


            review.alias ? newReview.find("[data-entity='alias']").html(review.alias) : newReview.find("[data-entity='alias']").html("Anónimo");
            review.provincia == 'NS/NC' ? newReview.find("[data-entity='region']").html(review.provincia) : newReview.find("[data-entity='region']").html('de ' + review.provincia);

            if (review.nota_mitja == 10 || (parseFloat(review.valoracion) * 2) == 10) {
                review.nota_mitja ? newReview.find("[data-entity='rate']").html(review.nota_mitja) : newReview.find("[data-entity='rate']").html((parseFloat(review.valoracion) * 2));
                newReview.find("[data-entity='nsnc']").html('');
            } else if (review.nota_mitja == 0 || review.valoracion == 0) {
                newReview.find("[data-entity='nsnc']").html('Ns/Nc');
                newReview.find("[data-entity='rate']").html("");
            } else {
                newReview.find("[data-entity='nsnc']").html('');
                review.nota_mitja ? newReview.find("[data-entity='rate']").html(parseFloat(review.nota_mitja).toFixed(1)) : newReview.find("[data-entity='rate']").html((parseFloat(review.valoracion) * 2).toFixed(1));

            }

            newReview.find("[data-entity='year']").html(reviewDate.getFullYear());
            newReview.find("[data-entity='month']").html(myApp.dateFormatter.getLongMonthName(reviewDate, 'es'));
            if (review.comentario_hotel_positivo || review.comentario_hotel_negativo) {
                if (review.comentario_hotel_positivo.length > 3) {
                    newReview.find("[data-entity='positive']").removeClass('hidden');
                    newReview.find("[data-entity='positiveText']").html(review.comentario_hotel_positivo);
                } else {
                    newReview.find("[data-entity='positive']").addClass('hidden');
                }
                if (review.comentario_hotel_negativo.length > 3) {
                    newReview.find("[data-entity='negative']").removeClass('hidden');
                    newReview.find("[data-entity='negativeText']").html(review.comentario_hotel_negativo);
                } else {
                    newReview.find("[data-entity='negative']").addClass('hidden');
                }
            } else if (review.comentario_positivo || review.comentario_negativo) {
                if (review.comentario_positivo.length > 3) {
                    newReview.find("[data-entity='positive']").removeClass('hidden');
                    newReview.find("[data-entity='positiveText']").html(review.comentario_positivo);
                } else {
                    newReview.find("[data-entity='positive']").addClass('hidden');
                }
                if (review.comentario_negativo.length > 3) {
                    newReview.find("[data-entity='negative']").removeClass('hidden');
                    newReview.find("[data-entity='negativeText']").html(review.comentario_negativo);
                } else {
                    newReview.find("[data-entity='negative']").addClass('hidden');
                }
            }

            if (review.contestacion_hotel != null && review.contestacion_hotel.length > 3) {
                newReview.find("[data-entity='answer']").removeClass('hidden');
                newReview.find("[data-entity='answerText']").html(review.contestacion_hotel);
            } else {
                newReview.find("[data-entity='answer']").addClass('hidden');
            }

            if (print) {
                this.newReviews += newReview[0].outerHTML;
            }
        } else {
            $(clonedElem).html('');
        }
    };

    this.printReviews = function (id, type) {
        $('.reviews-' + type + '-' + id).append(this.newReviews);
        if (self.arrayNoReviews.length > 0) {
            $('.reviews-' + type + '-' + id).append(this.clonedReviewCarousel);
        }
    };

    this.cloneReview = function (elem) {
        this.clonedReview = elem.first().clone();
    };

    this.cloneReviewCarousel = function (elem) {
        this.clonedReviewCarousel = elem.first().clone();
    };
    
    this.showMoreReviewsByReceiver = function (element) {
        var type, data, callback;
        var endLimit = $(element).data("start-review");
        var receiver = $(element).data('receiver');
        var url = $(element).data('url');
        data = {
            start: endLimit
        };
        
        if (receiver == 'hotel') {
            data.destiny = $("#destinyFilter").val();
            data.tematic = $("#tematicFilter").val();
        }
        
        if (!url) {
            url = myApp.config.routing[receiver+"Reviews"];
            if (myApp.config.routing[receiver+"Reviews"].endsWith("/")) {
                url = url + endLimit;
            } else {
                url = url + '/' + endLimit;
            }
        } 
        type = 'POST';
        callback = function callback(data) {
            $("#load-comments").remove();
            
            if ($(element).data('type') == 'filter') {
                $('[data-container="'+receiver+'-reviews"]').empty();
            }
            $('[data-container="'+receiver+'-reviews"]').append(data);
        };
        self.ajaxManager.ajaxCall(type, url, data, callback, element);
    };
    this.initialize();
};

export default ReviewsManager;