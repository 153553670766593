var FormFileManager = function () {
    "use strict";
    var self = this;

    this.initialize = function () {
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $("body").on("click", "[data-action=add-inputfile]", function () {
            self.addInputFile($(this).data('target'));
        });

        $('[data-open-input-file]').on('click', function () {
            var inputFileId=$(this).attr('data-open-input-file');
            $(inputFileId).trigger('click');
        });
    };

    this.addInputFile = function (target) {
        var baseInputFile = $("[data-inputfile='" + target + "']").first();
        var cloned = baseInputFile.clone().val('');
        var container = baseInputFile.siblings("[data-container='inputfiles']");
        cloned.appendTo(container);
    };
    this.initialize();
};

export default FormFileManager;