var VisibilityToggler = function () {

    var self = this;

    this.initialize = function () {
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $("body").on("blur", "[data-show-element]", function () {
            $($(this).data('show-element')).show();
        });
    };
    this.initialize();
};
export default VisibilityToggler;