export default class BookingContactFormValidator
{
    constructor()
    {
        this.eventDelegation();
    }

    eventDelegation()
    {
        $("#booking-contact")
            .formValidation({
                live: 'disabled',
                icon: myApp.formValidator.getFeedbackIcons(),
                fields: {
                    id_reserva: {
                        validators: {
                            notEmpty: {
                                message: myApp.translator['contact.contactus.body.hasbooking.form.errors.idbooking']
                            }
                        }
                    },
                    dni: {
                        validators: {
                            notEmpty: {
                                message: myApp.translator['contact.contactus.body.hasbooking.form.errors.dni']
                            }
                        }
                    }
                }
            })
            .on('keyup keypress', function (e)
            {
                var keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();
                }
            })
            .on('success.field.fv', function (e, data)
            {
                myApp.formValidator.disableSuccessAlerts(e, data);
            })
            .on('err.field.fv', function (e, data)
            {
                $('#booking-contact-options').val(0);
                myApp.formValidator.disableContinuousErrorValidation(e, data);
            })
            .on('success.form.fv', function (e)
            {
                e.preventDefault();
            });
    }
}


