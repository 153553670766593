import AjaxManager from "../ajaxManager";

export default class ActivityReviewManager {
    constructor() {
        this.ajaxManager = new AjaxManager();
        this.eventDelegation();

    }

    eventDelegation() {
        $('body').on('click', '[data-show-activity-reviews]', (event) => {
            let activityId = $(event.currentTarget).data('show-activity-reviews');
            let offsetComment = $(event.currentTarget).data('offset-comment');
            let offsetNoComment = $(event.currentTarget).data('offset-no-comment');
            let maxCommentResults = $(event.currentTarget).data('max-comment-results');
            let maxNoCommentResults = $(event.currentTarget).data('max-no-comment-results');
            this.showReviews(activityId, offsetComment, offsetNoComment, maxCommentResults, maxNoCommentResults);
        });
    }

    showReviews(activityId, offsetComment, offsetNoComment, maxCommentResults, maxNoCommentResults) {
        let url = myApp.config.routing.activityReviewShow;
        let data = {
            activityId: activityId,
            offsetComment: offsetComment,
            offsetNoComment: offsetNoComment,
            maxCommentResults: maxCommentResults,
            maxNoCommentResults: maxNoCommentResults
        };
        $('[data-show-activity-reviews="' + activityId + '"] [data-loader]').show();
        let callback = (reviewView) => {
            $('[data-show-activity-reviews="' + activityId + '"]').remove();
            $('[data-reviews-container="' + activityId + '"]').append(reviewView);
        }

        this.ajaxManager.ajaxCall('POST', url, data, callback);
    }
}


