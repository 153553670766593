export default class MenuManager {
    constructor() {
        this.eventDelegation();
    }

    eventDelegation() {
        let menuNavigationOptions = $('[data-entity="menu-navigation-options"]');
        let menuUserPanelOptions = $('[data-entity="menu-user-panel-options"]');
        let closeMobileMenu = $('[data-entity="close-mobile-menu"]');
        let closeMobileMenuUserPanel = $('[data-entity="close-mobile-menu-user-panel"]');

        $('[data-entity="open-mobile-menu"]').on('click', (e) => {
            menuNavigationOptions.addClass('show-menu');
            $('body').addClass('modal-open');
            myApp.browserHistoryManager.addState('[data-entity="close-mobile-menu"]');
        });

        closeMobileMenu.on('click', (e) => {
            myApp.browserHistoryManager.removeState('[data-entity="close-mobile-menu"]');
        });

        closeMobileMenu.on('pop-state', (e) => {
            menuNavigationOptions.removeClass('show-menu');
            $('body').removeClass('modal-open');
        });

        closeMobileMenuUserPanel.on('click', (e) => {
            myApp.browserHistoryManager.removeState('[data-entity="close-mobile-menu-user-panel"]');
        });

        closeMobileMenuUserPanel.on('pop-state', (e) => {
            menuUserPanelOptions.removeClass('show-menu');
            $('body').removeClass('modal-open');
        });

        $('[data-action="menu-option-collapse"]').on('click', (e) => {
            if (myApp.deviceDetector.isXsScreen() || myApp.deviceDetector.isSmScreen()) {
                e.preventDefault();
                e.stopPropagation();
            }
            $(e.currentTarget).closest('.menu-option').toggleClass('active-menu');
        });

        $('[data-action="language-option-collapse"]').on('click', (e) => {
            $(e.currentTarget).toggleClass('active-menu');
        });

        $('[data-action="open-menu"]').on('click', (event) => {
            let collapseId = $(event.currentTarget).data('id');
            myApp.browserHistoryManager.addState('[data-collapse-id="' + collapseId + '"]');
        });

        $('[data-action="close-menu"]').on('click', (event) => {
            let collapseId = $(event.currentTarget).data('id');
            myApp.browserHistoryManager.removeState('[data-collapse-id="' + collapseId + '"]');
        });

        $('[data-collapse="menu-collapse"]').on('pop-state', (event) => {
            $('#containerUserMenu, #filter').removeClass('show');
            $('body').removeClass('filters-opened');
            $('#menu_overlay').hide();
        });

        const userProfileImg = document.querySelectorAll('[data-entity="userProfileImage"]');
        userProfileImg.forEach((element) => {
            if (!element.getAttribute('data-src')) {
                this.createUserInitials(element);
            }

            element.addEventListener('error', (e) => {
                const currentTarget = e.currentTarget;
                this.createUserInitials(currentTarget);
            });
        });
    }

    createUserInitials(currentTarget) {
        let userInitials = currentTarget.getAttribute('data-user-initials');
        let newProfileImg = document.createElement('div');
        newProfileImg.innerHTML = userInitials ? userInitials : 'U';
        newProfileImg.classList.add('profile-picture', currentTarget.getAttribute('data-error-class') ?? 'default');
        currentTarget.parentNode.insertBefore(newProfileImg, currentTarget.parentNode.firstChild);
        currentTarget.remove();
    }
}
