require('time-to');

var TimeToManager = function () {

    "use strict";

    var self = this;

    this.initialize = function () {
        $.each($('[data-time-to]'), function () {
            var endDate = $(this).data('date');
            var d = new Date(endDate.replace(/\-/g, '\/').replace(/[T|Z]/g, ' '));
            self.initializeTimeTo($(this), d);
        });
    };

    this.initializeTimeTo = function (element, date) {

        var daysText = myApp.translator['countdown.days'];
        var hoursText = myApp.translator['countdown.hours'];

        if (element.data('remaining-days') == 1) {
            daysText = myApp.translator['countdown.day'];
        }

        if (element.data('remaining-hours') == 1) {
            hoursText = myApp.translator['countdown.hour'];
        }

        let currentDate = new Date();
        let dateTextCET = currentDate.toLocaleString(
            "en-US",
            { timeZone: "Europe/Madrid" }
        );
        let dateCET = new Date(dateTextCET);
        let hoursDiff = (dateCET - currentDate) / 3600000;
        date.setHours(date.getHours() - Math.round(hoursDiff))

        element.timeTo({
            timeTo: date,
            displayDays: 2,
            fontSize: 30
        });
        element.removeClass('timeTo-white');
        $(element).find("div:eq(1)").after("<p class='timeTo-text days'>" + daysText + "</p>");
        $(element).find("div:eq(1)").after("<p class='timeTo-text hours'>" + hoursText + "</p>");
        $(element).find("div:eq(1)").after("<p class='timeTo-text minutes'>" + myApp.translator['countdown.min'] + "</p>");
        $(element).find("div:eq(1)").after("<p class='timeTo-text seconds'>" + myApp.translator['countdown.sec'] + "</p>");
    }
    this.initialize();
};

export default TimeToManager;
