export default class ModalScrollManager
{
    constructor() {
        this.currentScrollPosition = 0;
        this.eventDelegation();
    }

    eventDelegation() {
        $('[data-action="open-modal-scroll"]').on('click', (event) => {
            this.openModal();
        });

        $('[data-action="close-modal-scroll"]').on('click', (event) => {
            this.closeModal();
        });
    }

    openModal() {
        this.currentScrollPosition = window.scrollY;
        $('body').addClass('fixed-body');
    }

    closeModal() {
        $('body').removeClass('fixed-body');
        window.scrollTo(0, this.currentScrollPosition);
        this.currentScrollPosition = 0;
    }
}
