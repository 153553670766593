var BlockRealignmentService = function () {

    "use strict";

    var self = this;

    this.initialize = function () {
        this.eventDelegation();
        this.realignItems();
    };

    this.eventDelegation = function () {
        $(window).on("resize", function (event) {
            self.realignItems();
        });
    };

    this.realignItems = function () {
        $("[data-realign]").each(function (index) {
            self.doRealign('#' + $(this).attr('id'));
        });
    };

    this.doRealign = function (item) {
        var container;
        if (myApp.deviceDetector.isXsScreen()) {
            container = $(item).data("realign-xs");
        } else if (myApp.deviceDetector.isSmScreen()) {
            container = $(item).data("realign-sm");
        } else if (myApp.deviceDetector.isMdScreen()) {
            container = $(item).data("realign-md");
        } else if (myApp.deviceDetector.isLgScreen()) {
            container = $(item).data("realign-lg");
        }
        $(item).appendTo(container);
    };
    this.initialize();
};

export default BlockRealignmentService;