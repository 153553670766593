import DataLayerGenerator from "./dataLayerGenerator";

export default class LoginDataLayerGenerator {
    constructor() {
        this.dataLayerGenerator = new DataLayerGenerator();
        this.generateLoginDataLayerData();
    }

    generateLoginDataLayerData() {

        const cookieUserGA = this.getGACookie();
        const userId = this.getUserIdCookie() ? this.getUserIdCookie() : null;
        const userStatus = userId ? "logged" : "unlogged";
        const cookieUserType = userId ? this.getUserTypeTrackingTextCookie() : null;

        this.sendEvent(cookieUserGA, userId, userStatus, cookieUserType);
    }

    getGACookie(){
        let cookieUserGA = myApp.browserStorageService.getCookie('_ga');
        return cookieUserGA.replace("GA1.2.", "");
    }

    getUserIdCookie(){
        return myApp.browserStorageService.getCookie('usuario_web_id');
    }

    getUserTypeTrackingTextCookie(){
        const cookieUserTypeTrackingText = myApp.browserStorageService.getCookie('userTypeTrackingText');
        return decodeURIComponent(cookieUserTypeTrackingText).replace(/[+]/g, ' ');
    }

    sendEvent(cookieUserGA, userId, userStatus, cookieUserType){
        let datalayer = {
            user_ga: cookieUserGA,
            user_id: userId,
            user_status: userStatus,
            user_type: cookieUserType
        }

        this.dataLayerGenerator.generate(datalayer);
    }

}
