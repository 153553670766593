var DatepickerValidator = function () {

    "use strict";

    var self = this;
    

    this.revalidateDatepickerField = function (datepicker) {
        $(datepicker).closest('form').formValidation('revalidateField', $(datepicker).attr('name'));
    };   
};

export default DatepickerValidator;