import ScrollManager from "@web/vpt-assets/assets/js/utilities/scrollManager";

export default class ClientReplyFormValidator {
    constructor() {
        this.scrollManager = new ScrollManager();
        this.eventDelegation();
    }

    eventDelegation() {
        // The maximum number of options
        const MAX_OPTIONS = 8;

        $("#form_contestar")
            .formValidation({
                framework: 'bootstrap',
                icon: myApp.formValidator.getFeedbackIcons(),
                fields: {
                    aceptacion: {
                        enabled: true,
                    },
                    observaciones_contacto: {
                        enabled: true,
                    },
                    'attachment[]': {
                        enabled: true, validators: {
                            file: {
                                extension: 'jpeg,jpg,png,gif,pdf,doc,docx,doc,txt,odt,xls,csv,rtf,xps',
                                type: 'image/jpeg,image/png,image/gif,application/pdf,text/plain,application/msword,application/vnd.ms-excel,application/rtf',
                                maxSize: 5242880, // 5 MB
                                message: myApp.translator['forms.clientreply.errors.validation.invalid.attachment']
                            }
                        }
                    },
                    iban: {
                        excluded: false,
                        validators: {
                            notEmpty: {
                                message: myApp.translator['forms.clientreply.errors.validation.empty.iban']
                            }, iban: {
                                message: myApp.translator['forms.clientreply.errors.validation.invalid.iban']
                            }
                        }
                    }
                }
            })
            .on('change', '[name="attachment[]"]', function (e) {
                $('#form_contestar').formValidation('enableFieldValidators', 'observaciones', false);
                $('#form_contestar').formValidation('revalidateField', 'observaciones');
            })
            // Add button click handler
            .on('click', '.addButton', function () {
                var $newAttach = $('#newAttachment'), $clone = $newAttach
                    .clone()
                    .removeClass('hide')
                    .removeAttr('id')
                    .insertBefore($newAttach), $option = $clone.find('[name="attachment[]"]');

                // Add new field
                $('#form_contestar').formValidation('addField', $option);
            })

            // Remove button click handler
            .on('click', '.removeButton', function () {
                var $row = $(this).parents('.form-group'), $option = $row.find('[name="attachment[]"]');

                // Remove element containing the option
                $row.remove();

                // Remove field
                $('#form_contestar').formValidation('removeField', $option);
            })

            // Called after adding new field
            .on('added.field.fv', function (e, data) {
                // data.field   --> The field name
                // data.element --> The new field element
                // data.options --> The new field options

                if (data.field === 'attachment[]') {
                    if ($('#form_contestar').find(':visible[name="attachment[]"]').length >= MAX_OPTIONS) {
                        const addButton = $('#form_contestar').find('.addButton');
                        addButton.attr('disabled', 'disabled');
                        addButton.addClass('disabled');
                    }
                }
            })
            // Called after removing the field
            .on('removed.field.fv', function (e, data) {
                if (data.field === 'attachment[]') {
                    if ($('#form_contestar').find(':visible[name="attachment[]"]').length < MAX_OPTIONS) {
                        const addButton = $('#form_contestar').find('.addButton');
                        addButton.removeAttr('disabled');
                        addButton.removeClass('disabled');
                    }
                }
            })
            .on('keyup', '[data-copy-keyup="iban"]', function (e) {
                setTimeout(function () {
                    $('#form_contestar')
                        //Small delay to revalidate after the hidden field is updated
                        .formValidation('revalidateField', 'iban');
                }, 200);
            })
            .on('success.form.fv', (e) => {
            e.preventDefault();

            const form = e.currentTarget;
            const validator = $(form).data('formValidation');

            const fieldsToCheck = [
                'observaciones_contacto',
                'iban-input',
                'attachment[]',
                'aceptacion'
            ];

            let atLeastOneFieldFilled = false;

            fieldsToCheck.forEach(fieldName => {
                const fieldElements = validator.getFieldElements(fieldName);

                const isFieldFilled = Array.from(fieldElements).some(input => input.value.trim() !== "");

                if (isFieldFilled) {
                    atLeastOneFieldFilled = true;
                }
            });

            if (atLeastOneFieldFilled) {
                validator.defaultSubmit();
            } else {
                if ($('#contactError').empty()) {
                    $('#contactError').append(myApp.translator['forms.clientreply.errors.validation.invalid.overall']);
                }
            }
            });
    }
}

