export default class RequestInvoiceValidator {
    constructor() {
        this.passport = 'Passport';
        this.dniValidationEnabled = true;
        this.countryValidationEnabled = false;
        this.forbiddenDni = ['11111111H', '22222222J', '33333333P', '44444444A', '55555555K', '66666666Q', '77777777B', '88888888Y', '99999999R'];
        this.arrayFieldsShowCountry = ['3', 'id'];
        this.validator = this.getValidatorConfig();
        this.eventDelegation();
    }

    eventDelegation() {
        const invoices = document.querySelectorAll('[data-request-invoice]');

        for (let invoice of invoices) {
            let invoiceId = invoice.getAttribute('data-id');

            $('#requestInvoiceForm' + invoiceId).formValidation(this.validator)
                .on('keyup keypress', (e) => {
                    const keyCode = e.keyCode || e.which;
                    if (keyCode === 13) {
                        e.preventDefault();
                    }
                })
                .on('err.field.fv', (e, data) => {
                    e.preventDefault();

                    data.element.removeClass('border-success');

                    if (data.element.hasClass('vpt-input') || data.element.hasClass('vpt-input-full') || data.element.hasClass('vpt-select') || data.element.hasClass('vpt-select-full')) {
                        data.element.addClass('border-error');
                    }
                })
                .on('success.field.fv', (e, data) => {
                    $('[data-container="form-error"]' + ' ul').find('li[data-field="' + data.field + '"]').remove();

                    data.element.removeClass('border-error');

                    if (data.element.hasClass('vpt-input') || data.element.hasClass('vpt-input-full') || data.element.hasClass('vpt-select') || data.element.hasClass('vpt-select-full')) {
                        data.element.addClass('border-success');
                    }
                })
                .on('err.form.fv', (e) => {
                    e.preventDefault();
                    myApp.scrollManager.scrollToElement($('#requestInvoiceForm' + invoiceId + ' .border-error'));
                })
                .on('success.form.fv', (e) => {
                    const currentTarget = e.currentTarget;
                    if ($(currentTarget).data('formValidation').isValid()) {
                        e.preventDefault();
                        currentTarget.submit(e);
                    }
                })
                .on('change', '[name="dni_nif"]', (e) => {
                    this.toggleIdValidation(e.currentTarget);
                })
                .on('change', '#copy_to', (e) => {
                    const currentTarget = e.currentTarget;
                    const copyTo = currentTarget.getAttribute('data-copy-to');
                    const formDestination = $("#requestInvoiceForm" + copyTo);
                    const copyFrom = currentTarget.getAttribute('data-copy-from');
                    const formOrigin = $("#requestInvoiceForm" + copyFrom);

                    if (currentTarget.checked === true) {
                        this.copyForms(formOrigin, formDestination);
                    } else {
                        formDestination.trigger("reset");
                        $('[name="dni_nif"]', formDestination).trigger("change");
                    }
                });
        }

        $('[name="dni_nif"]').each((i, e) => {
            this.toggleIdValidation(e);
        });
    };

    toggleIdValidation(field) {
        let invoiceId = field.getAttribute("data-invoice");
        if ($.inArray($(field).val(), this.arrayFieldsShowCountry) !== -1) {
            this.dniValidationEnabled = false;
            this.countryValidationEnabled = true;
            $('[data-container="country-picker-' + invoiceId + '"]').show();
        } else {
            this.dniValidationEnabled = true;
            this.countryValidationEnabled = false;
            $('[data-container="country-picker-' + invoiceId + '"]').hide();
        }

        let invoice = $("#requestInvoiceForm" + invoiceId);
        invoice.formValidation('enableFieldValidators', 'client_nif', this.dniValidationEnabled, 'vat');
        invoice.formValidation('enableFieldValidators', 'country', this.countryValidationEnabled, 'notEmpty');
    };

    copyForms(formOrigin, formDestination) {
        $(':input[name]', formDestination).each((i, element) => {
            if (element.getAttribute('type') !== 'hidden') {
                element.value = $(':input[name=' + element.name + ']', formOrigin).val();
            }
        });
        $('[name="dni_nif"]', formDestination).trigger("change");
    };

    getValidatorConfig() {
        return {
            live: 'disabled',
            icon: myApp.formValidator.getFeedbackIcons(),
            fields: {
                client: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.name']
                        }
                    }
                },
                client_nif: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.dni']
                        },
                        vat: {
                            country: 'ES',
                            message: myApp.translator['forms.booking.errors.contactdata.invalid.dni'],
                            transformer: function ($field, validatorName, validator) {
                                var value = $field.val();
                                return value.toUpperCase();
                            }
                        }
                    }
                },
                client_direccio: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['invoicing.requestinvoice.body.form.errors.address']
                        }
                    }
                },
                client_postal: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['invoicing.requestinvoice.body.form.errors.postalcode']
                        }
                    }
                },
                client_provincia: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['invoicing.requestinvoice.body.form.errors.region']
                        }
                    }
                },
                client_poblacio: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['invoicing.requestinvoice.body.form.errors.town']
                        }
                    }
                },
                country: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.country']
                        }
                    }
                },
                codi_pais: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.country']
                        }
                    }
                }
            }
        };
    }
};
