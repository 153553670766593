var Graph = function (graphId, type, labels, data) {

    "use strict";
    var self = this;
    this.graphId = graphId;
    this.type = type;
    this.labels = labels;
    this.data = data;



    this.initialize();
};

Graph.prototype.initialize = function () {
    var self = this;
    if (typeof Chart == 'undefined') {
        $.when($.getScript('//cdnjs.cloudflare.com/ajax/libs/Chart.js/2.4.0/Chart.min.js'),
                $.Deferred(function (deferred) {
                    $(deferred.resolve);
                })
                ).done(function () {
            self.initializeGraph();
        });
    } else {
        self.initializeGraph();
    }
};

Graph.prototype.initializeGraph = function () {
    $('#' + this.graphId).remove();
    $('.container-' + this.graphId).html('<canvas id="' + this.graphId + '" class="hotel-graph"></canvas>');
    var ctx = $('#' + this.graphId);
    var graphElem = new Chart(ctx, {
        id: this.graphId,
        type: this.type,
        data: {
            labels: this.labels,
            datasets: [
                {
                    label: "Nota",
                    borderColor: "rgb(154,28,24)",
                    fill: false,
                    pointBorderColor: "rgb(239,221,218)",
                    pointBackgroundColor: "rgb(239,221,218)",
                    data: this.data,
                    scaleIntegersOnly: true,
                    scaleBeginAtZero: false
                }
            ]
        },
        options: {
            bezierCurve: true,
            bezierCurveTension: 0.4,
            pointDot: true,
            pointDotRadius: 4,
            pointDotStrokeWidth: 1,
            pointHitDetectionRadius: 20,
            datasetStroke: true,
            datasetStrokeWidth: 2,
            datasetFill: true,
            scaleLabel: "<%= '  ' + value%>",
            tooltipTemplate: "Nota: <%= value %>",
            tooltipFontSize: 12,
            legendTemplate: "<ul class='<%=name.toLowerCase()%>-legend'><% for (var i=0; i<segments.length; i++){%><li><span style='-moz-border-radius:7px 7px 7px 7px; border-radius:7px 7px 7px 7px; margin-right:10px;width:15px;height:15px;display:inline-block;background-color:<%=segments[i].fillColor%>'> </span><%if(segments[i].label){%><%=s egments[i].label%><%}%></li><%}%></ul>",
            scales: {
                yAxes: [{
                        display: true,
                        ticks: {
                            min: 1,
                            max: 10,
                            stepSize: 2.75
                        },
                        gridLines: {
                            color: "#ddd"
                        }
                    }],
                xAxes: [{
                        gridLines: {
                            color: "#ddd"
                        }
                    }]
            },
            legend: {
                display: false
            }
        }
    });
};

export default Graph;
