export default class SendBudgetFormValidator
{
    constructor()
    {
        this.eventDelegation();
    }

    eventDelegation()
    {
        $("#sendBudgetForm")
            .formValidation({
                live: 'disabled',
                icon: myApp.formValidator.getFeedbackIcons(),
                fields: {
                    name: {
                        validators: {
                            notEmpty: {
                                message: myApp.translator['forms.booking.errors.contactdata.empty.name']
                            }
                        }
                    },
                    surname: {
                        validators: {
                            notEmpty: {
                                message: myApp.translator['forms.booking.errors.contactdata.empty.surname']
                            }
                        }
                    },
                    email: {
                        validators: {
                            notEmpty: {
                                message: myApp.translator['forms.booking.errors.contactdata.empty.email']
                            },
                            regexp: {
                                regexp: myApp.formValidator.getEmailValidatorRegExp(),
                                message: myApp.translator['forms.booking.errors.contactdata.invalid.email']
                            }
                        }
                    },
                    friendemail: {
                        validators: {
                            notEmpty: {
                                message: myApp.translator['budget.errors.friendemail']
                            },
                            regexp: {
                                regexp: myApp.formValidator.getEmailValidatorRegExp(),
                                message: myApp.translator['forms.booking.errors.contactdata.invalid.email']
                            }
                        }
                    }
                }
            })
            .on('keyup keypress', (e) => {
                const keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();
                }
            })
            .on('err.field.fv', (e) => {
                e.preventDefault();
            })
            .on('err.form.fv', (e) => {
                e.preventDefault();
            })
            .on('success.form.fv', (e) => {
                e.preventDefault();
                myApp.sendBudget.send();
            });
    }
}
