import Graph from "../entity/graph";
var HotelChartBuilder = function () {

    "use strict";

    var self = this;
    this.arrayMonths;
    this.arrayDates;
    this.arrayYears;
    this.arrayRates;

    this.initialize = function () {
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $("body").on("click", ".show-chart", function () {
            var idHotel = $(this).attr('data-idHotel');
            let existsModal = $(`#map-hotel-${idHotel}-m div`).is(':visible');
            existsModal ? self.initializeChart(idHotel+'-m', this) : self.initializeChart(idHotel, this);
        });
    };

    this.initializeChart = function (idHotel, elem) {
        $('.show-chart').removeClass('active');
        if ($(elem).hasClass('hide-graph') && $('#graph-hotel-' + idHotel).is(":visible")) {
            $('#graph-hotel-' + idHotel).hide();
        } else {
            $(elem).addClass('active');
            self.generateChart(idHotel, $(elem).attr('data-service'));
            self.changeDom($(elem), idHotel);
        }
    };

    this.changeDom = function (elem, idHotel) {
        if ((!$(elem).hasClass('hide-graph')) && (!$('#graph-hotel-' + idHotel).is(":visible"))) {
            myApp.textTransformer.changeText($('#graph-container-' + idHotel + ' .hide-graph'));
        }
        if (!$('#graph-hotel-' + idHotel).is(":visible")) {
            setTimeout(function () {
                $('#graph-hotel-' + idHotel).show();
            }, 150);
        }

        $('#graph-hotel-' + idHotel + ' .service-name').html($(elem).attr('data-serviceName'));
        $('#graph-hotel-' + idHotel + ' i').removeClass().addClass('fa ' + $(elem).attr('data-icon') + ' color-main');
        if ($(elem).attr('data-service') != 'rate') {
            $('#graph-hotel-' + idHotel + ' .global-evolution').show();
        } else {
            $('#graph-hotel-' + idHotel + ' .global-evolution').hide();
        }
    }
    ;

    this.generateChart = function (idHotel, service) {
        this.service = service;
        $.when(self.setHotelData(idHotel, service)).done(function () {
            self.printChart(idHotel);
        });
    };

    this.setHotelData = function (idHotel, service) {
        var deferred = $.Deferred();
        $.getJSON(myApp.config.rootPath + 'chart/' + idHotel, function (data) {
        }).done(function (data) {
            self.saveHotelData(data, idHotel, service);
            deferred.resolve(data);
        });

        return deferred.promise();
    };

    this.saveHotelData = function (data, idHotel, service) {
        var arrayMonths = new Array();
        var arrayRates = new Array();
        var arrayDates = new Array();
        var arrayYears = {};
        $.each(data, function () {
            var date = new Date(this.date);
            arrayDates.push(date);
            switch (service) {
                case 'rate':
                    arrayRates.push(this.rate);
                    break;
                case 'rooms':
                    arrayRates.push(this.rooms);
                    break;
                case 'services':
                    arrayRates.push(this.services);
                    break;
                case 'cleanliness':
                    arrayRates.push(this.cleanliness);
                    break;
                case 'food':
                    arrayRates.push(this.food);
                    break;
                case 'staff':
                    arrayRates.push(this.personal);
                    break;
                case 'price':
                    arrayRates.push(this.price);
                    break;
            }

        });
        if (arrayDates.length > 14) {
            self.removeElementsRandomly(14, arrayDates, arrayRates);
        }
        $.each(arrayDates, function () {
            var fullDate = new Date(this);
            if (arrayYears[fullDate.getFullYear()]) {
                arrayYears[fullDate.getFullYear()] = arrayYears[fullDate.getFullYear()] + 1;
            } else {
                arrayYears[fullDate.getFullYear()] = 1;
            }
            arrayMonths.push(myApp.dateFormatter.getShortMonthName(fullDate, 'es'));
        });

        self.arrayRates = arrayRates;
        self.arrayMonths = arrayMonths;
        self.arrayYears = arrayYears;
    };


    this.printChart = function (idHotel) {
        new Graph('chart-hotel-' + idHotel, 'line', this.arrayMonths, this.arrayRates);
        this.printYears(idHotel);
    };

    this.printYears = function (idHotel) {
        var length = Object.keys(this.arrayMonths).length;
        var html = '';
        $.each(this.arrayYears, function (index) {
            var percentage = parseInt(this) / length * 100;
            html += "<div class='year-container' style='width: " + percentage + "%'>" + index + "</div>";
        });
        $('#chart-years-' + idHotel).html(html);
    };

    this.removeElementsRandomly = function (number, arrayMonths, arrayRates) {
        while (arrayMonths.length > number) {
            var randomValue = Math.floor(Math.random() * arrayMonths.length);
            if (randomValue != 0 && randomValue != (arrayMonths.length - 1)) {
                arrayMonths.splice(randomValue, 1);
                arrayRates.splice(randomValue, 1);
            }
        }
    };
    this.initialize();
};

export default HotelChartBuilder;