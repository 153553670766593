var Calendar = function (datepickerId, datepickerType) {

    "use strict";

    var self = this;

    this.datepickerId = datepickerId;
    this.datepickerType = datepickerType;
    this.datepickerElem;

    this.monthNames = new Array(12).fill("").map((e , i) =>  myApp.translator['calendar.month.' + ( i + 1 ) + '.long']);
    this.monthNamesShort = new Array(12).fill("").map((e , i) =>  myApp.translator['calendar.month.' + ( i + 1 ) + '.short']);
    this.dayNamesShort = new Array(7).fill("").map((e , i) =>  myApp.translator['calendar.week.' + ( i ) + '.short']);
    this.dayNamesMin = new Array(7).fill("").map((e , i) =>  myApp.translator['calendar.week.' + ( i ) + '.min']);

    this.initialize();

};

Calendar.prototype.initialize = function () {
    this.initializeDatepicker();
};


Calendar.prototype.initializeDatepicker = function () {
    var domDatepicker = $("#" + this.datepickerId);
    var minDate = 0, maxDate = null;

    if (typeof domDatepicker.data('max-day') != 'undefined' && domDatepicker.data('max-day') != '' &&
        typeof domDatepicker.data('min-day') != 'undefined' && domDatepicker.data('min-day') != '') {
        var minDateArray = domDatepicker.data('min-day').split("-");
        var maxDateArray = domDatepicker.data('max-day').split("-");
        minDate = new Date(minDateArray[0], minDateArray[1] - 1, minDateArray[2]);
        maxDate = new Date(maxDateArray[0], maxDateArray[1] - 1, maxDateArray[2]);
        maxDate.setDate(maxDate.getDate() - 1);
    }

    if (typeof this.datepickerType === 'undefined') {
        this.datepickerElem = domDatepicker.datepicker({
            showOtherMonths: false,
            selectOtherMonths: false,
            changeMonth: false,
            changeYear: false,
            dateFormat: 'dd-mm-yy',
            numberOfMonths: 1,
            firstDay: 1,
            rangeSelect: true,
            minDate: minDate,
            maxDate: maxDate,
            monthNames: this.monthNames,
            monthNamesShort: this.monthNamesShort,
            dayNamesShort: this.dayNamesShort,
            dayNamesMin: this.dayNamesMin,
            beforeShow: function (input, inst) {
                setTimeout(function () {
                    inst.dpDiv.css({
                        marginTop: -input.offsetHeight + 'px' + $(input).height(), 
                        left: $(input).offset().left
                    });
                }, 0);
                if (typeof domDatepicker.data('datepicker-rang') != 'undefined') {
                    $('body').on('mouseenter', '.ui-datepicker-calendar .ui-state-default', function () {
                        myApp.datepickerPrinter.hightlightDateRang(domDatepicker, $('#' + domDatepicker.data("start-datepicker")).datepicker("getDate"), this);
                    });
                }
            }
        });
    } else if (this.datepickerType === 'birthday') {
        var currentYear = (new Date).getFullYear();
        this.datepickerElem = domDatepicker.datepicker({
            firstDay: 1,
            changeMonth: true,
            changeYear: true,
            monthNames: this.monthNames,
            monthNamesShort: this.monthNames,
            dayNamesShort: this.dayNamesShort,
            dayNamesMin: this.dayNamesMin,
            dateFormat: 'dd-mm-yy',
            yearRange: (currentYear - 100).toString() + ':' + currentYear.toString()
        });
    }
};

Calendar.prototype.getDatepicker = function () {
    return this.datepickerElem;
};

Calendar.prototype.getDate = function () {
    return this.datepickerElem.datepicker("getDate");
};

Calendar.prototype.setDate = function (date) {
    return this.datepickerElem.datepicker("setDate", date);
};

Calendar.prototype.show = function () {

    this.datepickerElem.datepicker("show");
    return false;
};

Calendar.prototype.setMinDate = function (date) {
    this.datepickerElem.datepicker("option", "minDate", date);
};

Calendar.prototype.setMaxDate = function (date) {
    this.datepickerElem.datepicker("option", "maxDate", date);
};

Calendar.prototype.getMinDate = function() {
    return this.datepickerElem.datepicker("option", "minDate");
};

Calendar.prototype.getMaxDate = function() {
    return this.datepickerElem.datepicker("option", "maxDate");
};

export default Calendar;