var RaffleParticipationFormValidator = function ()
{
    "use strict";
    var self = this;

    this.initialize = function () {
        this.eventDelegation();
    };

    this.eventDelegation = function () {
        $("#raffleForm").formValidation(this.validator)
                .on('err.field.fv', function (e, data) {
                    myApp.formValidator.disableContinuousErrorValidation(e, data);
                })
                .on('success.field.fv', function (e, data) {
                    myApp.formValidator.disableSuccessAlerts(e, data);
                });

        $('[data-copy-keyup="raffle"]').on('change', () => {
            const fv = $("#raffleForm").data('formValidation');
            fv.updateStatus('raffleNum', 'NOT_VALIDATED');
        });
    };

    this.validator = {
        live: 'disabled',
        icon: myApp.formValidator.getFeedbackIcons(),
        fields: {
            name: {
                validators: {
                    notEmpty: {
                        message: myApp.translator['forms.booking.errors.contactdata.empty.name']
                    }
                }
            },
            surname_1: {
                validators: {
                    notEmpty: {
                        message: myApp.translator['forms.booking.errors.contactdata.empty.surname']
                    }
                }
            },
            birth_date: {
                validators: {
                    notEmpty: {
                        message: myApp.translator['raffle.active.body.form.error.year']
                    }
                }
            },
            region: {
                validators: {
                    notEmpty: {
                        message: myApp.translator['raffle.active.body.form.error.region']
                    }
                }
            },
            gender: {
                validators: {
                    notEmpty: {
                        message: myApp.translator['raffle.active.body.form.error.gender']
                    }
                }
            },
            device: {
                validators: {
                    notEmpty: {
                        message: myApp.translator['raffle.active.body.form.error.device']
                    }
                }
            },
            conditions: {
                validators: {
                    notEmpty: {
                        message: myApp.translator['raffle.active.body.form.error.conditions']
                    }
                }
            },
            raffleNum : {
                excluded: false,   
                validators: {
                    callback: {
                        callback: function() {
                            const input = $("[name='raffleNum']").first();
                            return /[0-9][0-9][0-9][0-9]/.test(input.val());
                        },
                        message: myApp.translator['raffle.active.body.form.error.raffle_num']
                    }
                }
            }
        }
    };

    this.initialize();
};

export default RaffleParticipationFormValidator;
