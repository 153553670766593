var DatepickerPrinter = function () {

    "use strict";

    var self = this;    

    this.initialize = function () {
    };

    this.hightlightDateRang = function (targetDatepicker, startDate, hoverDate) {
        var endDate;
        var currentDate = new Date(startDate);

        if (startDate != null && startDate != 'undefined') {
            if (hoverDate != null) {
                var day = $(hoverDate).text();
                var month = parseInt($(hoverDate).parent().attr('data-month')) + 1;
                var year = $(hoverDate).parent().attr('data-year');
                endDate = new Date(year + "-" + month + "-" + day);
            }

            if (endDate == null) {
                endDate = $(targetDatepicker).datepicker("getDate");
            }

            $('.ui-state-default').removeClass('ui-state-active');
            while (currentDate <= endDate) {
                self.hightlightDate(currentDate);
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }
    };

    this.hightlightDate = function (date) {
        var tdDay = self.findTdDay(date.getFullYear(), date.getMonth(), date.getDate());
        if (tdDay) {
            tdDay.find('a').addClass('ui-state-active');
        }
    };

    this.findTdDay = function (year, month, day) {
        var td = $('.ui-datepicker-calendar').find("[data-month='" + month + "'], [data-year='" + year + "']");
        var myDay = undefined;
        $.each(td, function () {
            if ($(this).find('a').html() == day) {
                myDay = $(this);
            }
        });
        return myDay;
    };
    this.initialize();
};

export default DatepickerPrinter;