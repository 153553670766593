import SliderManager from "../slider/sliderManager";
import CustomMapBuilder from "../customMap/customMapBuilder";
import CustomMap from "../../entity/customMap/customMap";
import $ from "jquery";

export default class GroupCardManager {
    constructor() {
        this.markerList = [];
        this.eventDelegation();
        this.checkNavigationLanguage();
        this.customMapBuilder = new CustomMapBuilder();
    }

    eventDelegation() {
        let activityModal = $('#groupActivity');
        let hotelModal = $('#groupHotel');
        let mapModal = $('#mapModal');
        let infoCholloModal = $('#info_chollo_modal');
        let currentModalOpenId = "";
        let numHotels = document.querySelectorAll('.hotel-summary-container [data-action="showHotelModal"]').length;
        let sinceContainer = document.querySelector("#sinceContainer");
        let doScroll = false;
        const body = $("body");

        infoCholloModal.on('custom-modal-hidden', () => {
            if (this.currentModalOpen && this.currentModalOpen.length > 0) {
                currentModalOpenId = this.currentModalOpen.attr('id');
            }
            if (currentModalOpenId === 'groupHotel') {
                this.currentModalOpen.find('.active-item').removeClass('active-item');
                this.currentModalOpen.find('[name="hotel-' + this.currentHotelId + '"]').addClass('active-item');
                myApp.customModalManager.openModal('#groupHotel');
            } else if (currentModalOpenId === 'groupActivity') {
                this.currentModalOpen.find('.active-item').removeClass('active-item');
                this.currentModalOpen.find('[name="' + this.activityId + '"]').addClass('active-item');
                myApp.customModalManager.openModal('#groupActivity');
            }

            hotelModal.find('.active-item').attr('data-scroll-to-reviews', doScroll);
            activityModal.find('.active-item').attr('data-scroll-to-reviews', doScroll);

            this.currentModalOpen = '';
            currentModalOpenId = '';
        })

        body.on("click", '[data-action="showHotelModal"]', (event) => {
            this.currentHotelContainer = '';
            let target = $(event.target);

            if (target.attr('data-entity') == 'conditionsContainer' || target.closest('[data-entity="conditionsContainer"]').length > 0) {
                this.currentHotelContainer = 'conditions';
            }
            this.currentHotelId = $(event.currentTarget).data('id');
            const hotelModalItem = hotelModal.find('[name="hotel-' + this.currentHotelId + '"]');

            const stickyBottomResume = document.querySelector('[data-entity="sticky-bottom-resume"]');
            if (stickyBottomResume) {
                stickyBottomResume.classList.remove('visible-checked');
                stickyBottomResume.classList.add('sticky-container-active');
                if (this.currentHotelId && hotelModalItem) {
                    hotelModalItem.addClass('pb-9');
                }
                if (numHotels > 1 && sinceContainer) {
                    sinceContainer.classList.add('d-none');
                }
            }

            if (infoCholloModal.length > 0) {
                this.currentModalOpen = hotelModal;
                myApp.browserHistoryManager.removeState('#info_chollo_modal');
            } else if (this.currentHotelId && hotelModalItem) {
                hotelModal.find('.active-item').removeClass('active-item');
                hotelModalItem.addClass('active-item');
                myApp.customModalManager.openModal('#groupHotel');
            }

            doScroll = target.attr('data-entity') == 'ratingContainer' || target.closest('[data-entity="ratingContainer"]').length > 0;
            hotelModal.find('.active-item').attr('data-scroll-to-reviews', doScroll);
        });

        body.on('click', '#groupHotel [data-element="modal-close"]', () => {
            const stickyBottomResume = document.querySelector('[data-entity="sticky-bottom-resume"]');
            hotelModal.find('.active-item').removeClass('active-item pb-9');
            if (stickyBottomResume) {
                stickyBottomResume.classList.remove('sticky-container-active');
                stickyBottomResume.classList.add('sticky-visible');
                myApp.customModalManager.closeModal('#groupHotel');
                if (numHotels > 1 && sinceContainer) {
                    sinceContainer.classList.remove('d-none');
                }
            }
        });


        body.on("click", "[data-action='showActivityModal']", (event) => {
            this.currentActivityContainer = '';
            let target = $(event.target);

            activityModal = $('#groupActivity');

            this.activityId = $(event.currentTarget).data('activity');

            if (infoCholloModal.length > 0) {
                this.currentModalOpen = activityModal;
                myApp.customModalManager.closeModal('#info_chollo_modal');
            } else {
                activityModal.find('.active-item').removeClass('active-item');
                activityModal.find('[name="' + this.activityId + '"]').addClass('active-item');
                myApp.customModalManager.openModal('#groupActivity');
            }

            doScroll = target.attr('data-entity') == 'ratingContainer' || target.closest('[data-entity="ratingContainer"]').length > 0;
            activityModal.find('.active-item').attr('data-scroll-to-reviews', doScroll);
        });

        $('[data-action="showMapModal"]').on('click', (event) => {
            this.currentHotelId = $(event.currentTarget).closest('[data-id]').data('id');
            mapModal.find('.active-item').removeClass('active-item');
            mapModal.find('[name="hotel-' + this.currentHotelId + '"]').addClass('active-item');
            myApp.customModalManager.openModal('#mapModal');
            event.stopPropagation();
            let hotelId = event.currentTarget.dataset.id;
            let mapContainer = $('#' + hotelId + '-custom-map-hotel-full');
            let mapId = mapContainer.data('hotel-map') || event.currentTarget.dataset.id;
            let map = this.customMapBuilder.getMap(event.currentTarget.dataset.sharedModal ? 'shared-hotels-map' : mapId + "-custom-map-hotel-full");

            if (!(map instanceof CustomMap) || mapContainer.html()?.trim() === "") {
                if (map instanceof CustomMap) {
                    this.customMapBuilder.removeMap(event.currentTarget.dataset.sharedModal ? 'shared-hotels-map' : mapId + "-custom-map-hotel-full");
                }
                map = this.buildFromDom(mapId, event.currentTarget.dataset.sharedModal ? 'shared-hotels-map' : "-custom-map-hotel-full");
            } else if (event.currentTarget.dataset.sharedModal) {
                map.invalidateSize();
            }
            setTimeout(() => {
                map.invalidateSize()
            }, 200);
        });

        activityModal.on('custom-modal-shown', (event) => {
            let activeActivity = activityModal.find('.active-item')
            let sliderManager;
            if (activeActivity.attr('initialized') !== 'true') {
                sliderManager = new SliderManager();
                let activityId = activeActivity.find('[data-gallery]:visible').data('gallery');
                if (activityId) {
                    sliderManager.addSlider('[data-gallery="' + activityId + '"]', this.scrollActivityReviews);
                    activeActivity.attr('initialized', 'true');
                } else {
                    this.scrollActivityReviews();
                }
            } else {
                this.scrollActivityReviews();
            }
        });

        activityModal.on('custom-modal-hide', (event) => {
            $(event.currentTarget).find('.custom-modal-body').scrollTop(0);
        });

        hotelModal.on('custom-modal-hide', (event) => {
            $(event.currentTarget).find('.custom-modal-body').scrollTop(0);
        });

        hotelModal.on('custom-modal-shown', (e) => {
            let sliderManager = new SliderManager();
            sliderManager.addSlider('[data-gallery="hotel-' + this.currentHotelId + '"]', this.scrollHotelReviews.bind(this));
            let mapContainer = hotelModal.find('[name="hotel-' + this.currentHotelId + '"] .hotel-custom-map');
            if (mapContainer.length) {
                let mapId = mapContainer.data('hotel-map');
                let map = this.customMapBuilder.getMap(mapId + "-custom-map-hotel");
                if (!(map instanceof CustomMap) || mapContainer.html().trim() === "") {
                    if (map instanceof CustomMap) {
                        this.customMapBuilder.removeMap(mapId + "-custom-map-hotel");
                    }
                    map = this.buildFromDom(mapId, '-custom-map-hotel');
                }
                setTimeout(() => {
                    map.invalidateSize()
                }, 500);
            }
            if (hotelModal.find('[name="hotel-' + this.currentHotelId + '"] .swiper-lazy').hasClass('swiper-lazy-loaded')) {
                this.scrollHotelReviews();
            }
        });

        body.on("click", "[data-action='scroll-to-hotel-reviews']", () => {
            let reviewsContainer = '[id="groupHotel"] [name="hotel-' + this.currentHotelId + '"] [data-entity="reviews"]';
            if ($(reviewsContainer).find('hotel-rating-summary').length > 0) {
                myApp.scrollManager.scrollContainerToElement(
                    '[id="groupHotel"] .custom-modal-body',
                    reviewsContainer
                );
            }
        });
        body.on("click", "[data-action='scroll-to-activity-reviews']", () => {
            let reviewsContainer = '[id="groupActivity"] .active-item:visible [data-entity="reviews"]';
            if ($(reviewsContainer).find('hotel-rating-summary').length > 0) {
                myApp.scrollManager.scrollContainerToElement(
                    '[id="groupActivity"] .custom-modal-body',
                    reviewsContainer
                );
            }
        });

        body.on("click", "[data-action='scroll-to-map']", () => {
            myApp.scrollManager.scrollContainerToElement(
                '#groupHotel .custom-modal-body',
                '#groupHotel [name="hotel-' + this.currentHotelId + '"] [data-entity="map"]'
            );
        });

        window.addEventListener('load', () => {
            if (myApp.config.clientCountryCode) {
                myApp.customModalManager.openModal('#no_available_group');
            }

            this.checkHighlightedChollo();
        });

        const cholloReview = document.querySelector('#chollo-review');
        if (cholloReview) {
            cholloReview.addEventListener('click', () => {
                const urlParams = new URLSearchParams(window.location.search);
                if (!urlParams.has('review')) {
                    urlParams.append('review', '');
                }

                window.location.href += '?' + urlParams.toString();
            });
        }
    }

    checkNavigationLanguage() {
        const navigationLanguages = navigator.languages.toString();
        if (navigationLanguages.includes('es')) {
            const showSuggestion = document.querySelectorAll('[data-entity="show-suggestion"]');
            if (!showSuggestion) return;
            showSuggestion.forEach((suggestion) => {
                suggestion.classList.remove('d-none');
            });
        }
    }

    scrollHotelReviews() {
        if ($('#groupHotel').find('.active-item').attr('data-scroll-to-reviews') == 'false') return;
        let reviewsContainer = '#groupHotel .active-item [data-entity="reviews"]';
        if ($(reviewsContainer).find('user-review').length > 0) {
            myApp.scrollManager.scrollContainerToElement(
                '#groupHotel .custom-modal-body',
                reviewsContainer
            );
        }
    }

    scrollActivityReviews() {
        if ($('#groupActivity').find('.active-item').attr('data-scroll-to-reviews') == 'false') return;
        let reviewsContainer = '#groupActivity .active-item [data-entity="reviews"]';
        if ($(reviewsContainer).find('user-review').length > 0) {
            myApp.scrollManager.scrollContainerToElement(
                '#groupActivity .custom-modal-body',
                reviewsContainer
            );
        }
    }

    buildFromDom(mapId, container) {
        let hotelData = $('[data-hotel-map="' + mapId + '"]');
        let latitude = $(hotelData).data('latitude');
        let longitude = $(hotelData).data('longitude');
        this.hotelMapId = container === 'shared-hotels-map' ? container : mapId + container;
        let map = this.customMapBuilder.addMap(
            this.hotelMapId,
            latitude,
            longitude,
            15
        );
        map.invalidateSize()

        let markerId = mapId + container;
        this.markerList = [];

        if (container === 'shared-hotels-map') {
            Array.from(document.querySelectorAll('[data-shared-modal]')).filter((marker, index, markers) => {
                return markers.findIndex(m => m.dataset.latitude === marker.dataset.latitude && m.dataset.longitude === marker.dataset.longitude) === index;
            }).forEach(function (marker) {
                const popupContent = $('[data-entity="popupContent-' + marker.dataset.id + '"]').html();
                this.markerList.push(this.customMapBuilder.addMarker(
                    this.hotelMapId,
                    marker.dataset.id + '-shared-hotel-map',
                    marker.dataset.latitude,
                    marker.dataset.longitude,
                    popupContent
                ));
                if (marker.dataset.id == mapId) {
                    markerId = marker.dataset.id + '-shared-hotel-map';
                }
            }.bind(this));
        } else {
            const popupContent = $('[data-entity="popupContent-' + mapId + '"]').html();
            this.customMapBuilder.addMarker(
                this.hotelMapId,
                markerId,
                latitude,
                longitude,
                popupContent
            );
        }

        map.addLayer();
        if (this.markerList.length > 1) {
            map.fitBounds(this.markerList);
        } else {
            map.invalidateSize();
        }
        map.fitBounds(this.markerList);

        map.map.on('popupopen', function (e) {
            $('[href="#close"]').removeAttr('href');
        });

        return map;
    }

    scrollToContainer() {
        let element = '#groupHotel [name="hotel-' + this.currentHotelId + '"] [data-entity="' + this.currentHotelContainer + '"]';
        if (this.currentHotelContainer && ($(element).find('hotel-rating-summary').length > 0 || $(element).find('[data-entity="pets"]').length > 0)) {
            myApp.scrollManager.scrollContainerToElement(
                '#groupHotel .custom-modal-body',
                element
            );
        }
    }

    checkHighlightedChollo() {
        const highlightedChollo = document.querySelector('input[name="hChollo"]');
        if (!highlightedChollo || !highlightedChollo.value) return;

        const searcherDestinationInput = document.querySelector('#destination-text');
        if (!searcherDestinationInput) return;
        searcherDestinationInput.innerHTML = highlightedChollo.value;
    }
}
