var ButtonManager = function () {

    this.disableButton = function (button) {
        $(button).prop('disabled', 'disabled');
    };

    this.activeButton = function (button) {
        $(button).prop('disabled', '');
    };
};

export default ButtonManager;