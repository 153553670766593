export default class VatCleaner
{
    clear(dni)
    {
        dni = dni.trim();
        dni = dni.toUpperCase();
        dni = dni.replace(/[^a-zA-Z0-9]/g, '');
        if (dni.length == 8 && dni.match(/[0-9]{7}[A-Z]{1}/)) {
            dni = "0" + dni;
        }
        return dni;
    }
}
