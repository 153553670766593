import AjaxManager from "../ajaxManager";

export default class HotelReviewManager {
    constructor() {
        this.ajaxManager = new AjaxManager();
        this.eventDelegation();
        this.loading = false;
    }

    eventDelegation() {
        $('body').on('click', '[data-show-hotel-reviews]', (event) => {
            if (this.loading) {
                return;
            }
            this.loading = true;
            let hotelId = $(event.currentTarget).data('show-hotel-reviews');
            let offset = $(event.currentTarget).attr('data-offset');
            let maxResults = $(event.currentTarget).data('max-results');
            let noCommentOffset = $(event.currentTarget).data('no-comment-offset');
            let noCommentMaxResults = $(event.currentTarget).data('no-comment-max-results');
            this.showReviews(hotelId, offset, maxResults, noCommentOffset, noCommentMaxResults);
        });
    }

    showReviews(hotelId, offset, maxResults, noCommentOffset, noCommentMaxResults) {
        let url = myApp.config.routing.hotelReviewShow;
        let data = {
            hotelId: hotelId,
            offset: offset,
            maxResults: maxResults,
            noCommentOffset,
            noCommentMaxResults
        };
        $('[data-show-hotel-reviews="' + hotelId + '"] [data-loader]').show();
        let callback = (reviewView) => {
            $('[data-show-hotel-reviews="' + hotelId + '"]').remove();
            $('[data-reviews-container="' + hotelId + '"]').append(reviewView);
            this.loading = false;
        }

        this.ajaxManager.ajaxCall('POST', url, data, callback);
    }
}
