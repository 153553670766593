import * as toastr from 'toastr';

var NotificationManager = function () {
    "use strict";

    var self = this;
    
    this.showNotification = function (type, data, options) {
        if (typeof options !== 'undefined') {
            this.setNotificationOptions(options);
        }
        switch (type) {
            case 'info':
                toastr.info(data);
                break;
            case 'success':
                toastr.success(data);
                break;
            case 'warning':
                toastr.warning(data);
                break;
            case 'error':
                toastr.error(data);
                break;
        }
    };

    this.createNotification = function (close_button, positionClass, showDuration, hideDuration, timeOut, showMethod, hideMethod, type, data) {
        toastr.options = {
            "closeButton": close_button,
            "debug": false,
            "newestOnTop": true,
            "progressBar": false,
            "positionClass": positionClass,
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": showDuration,
            "hideDuration": hideDuration,
            "timeOut": timeOut,
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": showMethod,
            "hideMethod": hideMethod
        };
        self.showNotification(type, data);
    };

    /**
     * @description Set notification options
     * @param options - Array of options key:value
     */
    this.setNotificationOptions = function(options){
        toastr.options = options;
    };
};

export default NotificationManager;
