import AcceptCookiesManager from "@web/vpt-assets/assets/js/utilities/cookies/acceptCookiesManager";
import LoginDataLayerGenerator from "./dataLayer/loginDataLayerGenerator";
import DataLayerManager from "./dataLayer/dataLayerManager";

export default class TrackersManager {
    constructor() {
        let googleTagManagerScript = document.querySelector('[data-entity="google-tag-manager-script"]');
        if (!googleTagManagerScript || typeof googleTagManagerScript === 'undefined' || !myApp.config.parameters.googleTagManagerKey) {
            return;
        }
        this.dataLayerManager = new DataLayerManager();
        let today = new Date().getTime();

        this.alwaysEnabled = myApp.config.parameters.isOffice || (today >=  new Date("2024-08-10 00:00:00").getTime() && today <= new Date("2024-08-11 23:59:00").getTime());
        this.acceptCookiesManager = new AcceptCookiesManager('cookies-accept', this.alwaysEnabled);

        this.updateCookieConsent();
        this.initializeDataLayers();
        this.eventDelegation();
    }

    eventDelegation() {
        document.querySelector('[data-entity="google-tag-manager-script"]').addEventListener(AcceptCookiesManager.onLoadEvent, () => {
            this.updateCookieConsent();
            this.initializeDataLayers();
        });
    }

    updateCookieConsent() {
        if (!this.acceptCookiesManager.cookiesStatus) {
            return;
        }

        function gtag() {
            if (typeof dataLayer != 'undefined') {
                dataLayer.push(arguments);
            }
        }

        gtag('consent', 'update', {
            ad_user_data: this.acceptCookiesManager.cookiesStatus,
            ad_personalization: this.acceptCookiesManager.cookiesStatus,
            ad_storage: this.acceptCookiesManager.cookiesStatus,
            analytics_storage: this.acceptCookiesManager.cookiesStatus
        });
        this.dataLayerManager.pushData(JSON.stringify({
            'event': 'cookie_consent_update'
        }));

        if (this.acceptCookiesManager.cookiesStatus === AcceptCookiesManager.granted) {
            this.dataLayerManager.pushData(JSON.stringify({
                'event': 'cookie_consent_preference'
            }));
            this.dataLayerManager.pushData(JSON.stringify({
                'event': 'cookie_consent_marketing'
            }));
            this.dataLayerManager.pushData(JSON.stringify({
                'event': 'cookie_consent_analytics'
            }));
        }
    }

    initializeDataLayers() {
        if (this.acceptCookiesManager.cookiesStatus !== AcceptCookiesManager.granted) {
            return;
        }
        new LoginDataLayerGenerator();
        if (myApp.purchaseDataLayer) {
            this.dataLayerManager.pushData(myApp.purchaseDataLayer);
            this.dataLayerManager.push(DataLayerManager.prototype.type_client);
        }
        if (myApp.enhancedConversionDataLayer) {
            this.dataLayerManager.pushData(myApp.enhancedConversionDataLayer);
        }
        if (myApp.refundDataLayer) {
            this.dataLayerManager.pushData(myApp.refundDataLayer);
        }
        if (myApp.checkoutDataLayer) {
            this.dataLayerManager.pushData(myApp.checkoutDataLayer);
        }
    }
}
